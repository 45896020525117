const ExcelJS = require("exceljs");

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const delay = (n) => new Promise((r) => setTimeout(r, n * 1000));

export const downloadExcel = (anfixdata) => {
  const workbook = new ExcelJS.Workbook();
  anfixdata.forEach((array) => {
    const worksheet = workbook.addWorksheet(array.title);

    const headerRow = worksheet.getRow(1);
    const columnKeys = Object.keys(array.value[0]);

    columnKeys.forEach((key, columnIndex) => {
      headerRow.getCell(columnIndex + 1).value = key;
      headerRow.getCell(columnIndex + 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "70AD47" },
      };
      headerRow.getCell(columnIndex + 1).font = { bold: true };
      headerRow.getCell(columnIndex + 1).alignment = { horizontal: "center" };
      headerRow.getCell(columnIndex + 1).border = { bottom: { style: "thin" } };
      const column = worksheet.getColumn(columnIndex + 1);
      column.width = key.length > 30 ? 30 : key.length + 3;
    });

    // Define el rango para autoFilter. Asume que el rango comienza en la fila 1, columna 1,
    // y termina en la última columna de la primera fila y en la última fila de los datos.
    const filterRange = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: array.value.length + 1, // +1 porque incluimos la fila del encabezado
        column: columnKeys.length,
      },
    };

    // Aplica el filtro automático a todas las columnas del rango definido.
    worksheet.autoFilter = filterRange;

    array.value.forEach((data, rowIndex) => {
      const row = worksheet.getRow(rowIndex + 2);
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true };
        cell.border = { bottom: { style: "thin" } };
      });

      columnKeys.forEach((key, columnIndex) => {
        const value = data[key];
        row.getCell(columnIndex + 1).value =
          value !== undefined && value !== null ? value : "---";
        row.getCell(columnIndex + 1).font = { name: "Century Gothic", size: 8 };
        if (
          (key === "Fecha asiento contable" ||
            key === "Fecha de la factura rectificativa" ||
            key === "Fecha de operación" ||
            key === "Fecha fra simplificada para SII" ||
            key === "Cobro/Pago estimado") &&
          row.getCell(columnIndex + 1).value !== "---"
        ) {
          row.getCell(columnIndex + 1).numFmt = "dd/mm/yyyy";

          const dateBruto = new Date(row.getCell(columnIndex + 1).value);
          const year = dateBruto.getFullYear();
          const month = dateBruto.getMonth() + 1;
          const day = dateBruto.getDate();

          row.getCell(columnIndex + 1).value = `${day}/${month}/${year}`;
        }

        if (rowIndex % 2 === 0) {
          row.getCell(columnIndex + 1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "E2EFE9" },
          };
        } else {
          row.getCell(columnIndex + 1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "D9E2F3" },
          };
        }
      });
    });
  });

  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      const blob = new Blob([buffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "TaulaConjunta.xlsx";
      link.click();

      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error al generar el archivo de Excel:", error);
    });
};
